import { createContext } from 'react';
import { Observable } from 'rxjs';
import { IWishList } from 'charlie-workflows/workflows/users/wishlist';
import {
	AssistantMessage,
	Celebrations,
	OccasionTopics,
	Product,
	Recipient,
	ThumbsState
} from 'charlie-workflows/types';
import { RecipientEntity } from 'charlie-workflows/contracts';
import { ExtendedRecipientInformation } from 'ollie-common/types/recipients.types';
import {
	IAnyMessage,
	SendMessageMetadata
} from 'ollie-common/types/messages.types';

export interface IChatProvider {
	isLoading?: boolean;
	isChatReadOnly?: boolean;
	typingIndicator?: string | null;
	chatId?: string;
	messages?: IAnyMessage[];

	messageInputValue?: string;
	setMessageInputValue: (message: string) => void;

	mode?: Celebrations | OccasionTopics | 'news' | null;
	wishlistProductMap?: Record<string, boolean>;
	feedbacks?: Record<string, ThumbsState>;
	recipient?: Partial<ExtendedRecipientInformation>;

	onCardClick: (product: Product) => void;
	onAddToWishlist?: (product: Product) => Promise<IWishList | null>;
	onSaveRecipient?: (
		recipient: Recipient
	) => Observable<RecipientEntity | null>;
	onUpdateRecipient?: (
		recipientId: string,
		body: Partial<Recipient>
	) => Observable<RecipientEntity>;
	onRecipientClick?: (recipient: RecipientEntity) => void;
	onRemoveFromWishlist?: (wishlistId: string, product: Product) => void;
	onSendFeedback?: (message: AssistantMessage, feedback: ThumbsState) => void;
	onSendMessage?: (details: SendMessageMetadata) => void;
	onSuggest?: (content: string) => void;
	onSendAudioMessage?: (file: Blob) => void;
	onShowMoreStepAction?: () => void;
	onShowMore?: (products: Product[]) => void;
	onShowElse?: (products: Product[]) => void;
	onShareLink?: () => void;
	onContinueConversation?: () => void;
}

export const ChatProvider = createContext<IChatProvider>({
	setMessageInputValue: () => {},
	onCardClick: () => {}
});
