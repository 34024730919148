import Link from 'next/link';
import { AffiliateLink } from '../../affiliate-link.component';
import { Modal } from '@/src/shared/ui-kit/components/modal';
import styles from './affiliate-link-modal.module.scss';

interface IAffiliateLinkModalProps {
  className?: string;
  onClose?: () => void;
}

export const AffiliateLinkModal: React.FC<IAffiliateLinkModalProps> = ({
  className,
  onClose,
}) => {
  const handleGotIt = () => {
    onClose?.();
  };

  return (
    <div className={`${styles.container} ${className || ''}`}>
      <AffiliateLink className={styles.affiliateIcon}></AffiliateLink>
      <Modal.Body className={styles.body}>
        <div className={styles.content}>
          <h1 className={styles.title}>Look for products that donate</h1>

          <p className={styles.text}>
            Products marked with this icon include an affiliate link. Ollie
            donates 100% of affiliate revenue to charity. Ollie remains
            committed to providing unbiased gift ideas.{' '}
            <Link className={styles.link} href={'/affiliate-link'}>
              Learn more
            </Link>
          </p>
        </div>

        <button className={styles.gotItButton} onClick={handleGotIt}>
          Got it!
        </button>
      </Modal.Body>
    </div>
  );
};
