import React, { useState, useEffect } from 'react';
import { MusicProduct } from 'charlie-workflows/types';

import styles from './music-product-card.module.scss';
import productCardStyles from '../../preview-product-card.module.scss';
import { logger } from '@/src/utils/common.utils';

export const MusicProductCard: React.FC<MusicProduct['payload']> = (props) => {
  const { productName, youtubeLink, workType } = props;

  const [isPlayClicked, setIsPlayClicked] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {};

  const sourceLinkUrl = new URL(youtubeLink);
  const id = sourceLinkUrl.searchParams.get('v');
  const list = sourceLinkUrl.searchParams.get('list');

  let embedUrl = '';

  if (id && list) {
    embedUrl = `https://www.youtube.com/embed/${id}?list=${list}`;
  } else if (id) {
    embedUrl = `https://www.youtube.com/embed/${id}`;
  } else if (list) {
    embedUrl = `https://www.youtube.com/embed/videoseries?list=${list}`;
  } else {
    logger("Neither id nor list is present. Can't generate embedUrl");
  }

  return (
    <div onClick={handleClick} className={productCardStyles.container}>
      <div className="productCardItemContainer">
        <iframe
          className={styles.videoPreview}
          src={embedUrl}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};
