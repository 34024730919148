import { MouseEvent } from 'react';
import styles from './affiliate-link.module.scss';

interface IAffiliateLinkProps {
  className?: string;
  onClick?: (e: MouseEvent) => void;
}

export const AffiliateLink: React.FC<IAffiliateLinkProps> = ({
  className,
  onClick,
}) => {
  return (
    <div className={`${styles.container} ${className || ''}`} onClick={onClick}>
      <div className={styles.svgContainer}>
        <svg
          className={styles.background}
          width="45"
          height="48"
          viewBox="0 0 45 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_7093_12753)">
            <path
              d="M4 4.95699H1.98925C1.4429 4.95699 1 5.39989 1 5.94624V5C1 2.79086 2.79086 1 5 1H38C41.3137 1 44 3.68629 44 7V43C44 45.2091 42.2091 47 40 47H10C7.79086 47 6 45.2091 6 43V6.95699C6 5.85242 5.10457 4.95699 4 4.95699Z"
              fill="#389EFC"
              stroke="#2E2E2E"
              stroke-width="2"
            />
            <path
              d="M30.7056 14.1323C30.0559 13.4021 29.1554 13 28.1698 13C27.0697 13 26.1161 13.5202 25.412 14.5042C25.3592 14.578 25.3099 14.6518 25.2636 14.7248C25.2173 14.6518 25.168 14.578 25.1152 14.5042C24.4111 13.5202 23.4575 13 22.3574 13C21.3718 13 20.4712 13.4021 19.8215 14.1322C19.205 14.8251 18.8655 15.7514 18.8655 16.7405C18.8655 17.8153 19.2826 18.8071 20.178 19.8618C20.9593 20.782 22.0799 21.7279 23.3776 22.8232C23.8571 23.2279 24.353 23.6464 24.8799 24.1031C24.99 24.1985 25.1268 24.2463 25.2635 24.2463C25.4003 24.2463 25.5371 24.1985 25.6472 24.1031C26.174 23.6466 26.6698 23.228 27.1493 22.8234C27.9827 22.1199 28.7024 21.5125 29.327 20.9232C30.5396 19.7791 31.6617 18.4806 31.6617 16.7405C31.6617 15.7514 31.3221 14.8251 30.7056 14.1323ZM28.5231 20.0711C27.9221 20.6381 27.2139 21.2359 26.3938 21.9281C26.0289 22.236 25.6547 22.5518 25.2636 22.8872C24.8723 22.5518 24.498 22.2359 24.1332 21.928C21.5788 19.772 20.037 18.4707 20.037 16.7405C20.0369 15.276 21.0344 14.1715 22.3573 14.1715C23.0641 14.1715 23.6652 14.5042 24.1438 15.1604C24.5367 15.6988 24.6979 16.257 24.699 16.2608C24.7693 16.5146 25.0002 16.6902 25.2635 16.6902C25.5268 16.6902 25.7577 16.5146 25.828 16.2608C25.8338 16.2399 26.4268 14.1715 28.1698 14.1715C29.4926 14.1715 30.4902 15.276 30.4902 16.7405C30.4902 17.8017 29.9387 18.7355 28.5231 20.0711Z"
              fill="white"
            />
            <path
              d="M33.2361 26.8057C32.7502 26.8057 32.2814 27.0097 31.9501 27.3653L30.2285 29.2135C30.006 28.4981 29.3378 27.9772 28.5502 27.9772H25.8442C25.4654 27.9772 25.1001 27.8393 24.8157 27.5891C23.286 26.243 20.9869 26.2311 19.4427 27.5518C19.1243 27.101 18.5996 26.8057 18.0068 26.8057H15.5858C15.2623 26.8057 15 27.0679 15 27.3915V34.4204C15 34.7438 15.2622 35.0061 15.5858 35.0061H18.0068C18.5328 35.0061 19.0053 34.7734 19.3276 34.406L19.6745 34.5035C20.861 34.837 22.0875 35.0061 23.3199 35.0061H28.5501C29.8338 35.0061 31.06 34.3929 31.8303 33.366L34.6424 29.6165C34.6424 29.6164 34.6425 29.6164 34.6426 29.6163C34.872 29.3103 34.9933 28.9461 34.9933 28.5629C34.9933 27.5939 34.205 26.8057 33.2361 26.8057ZM18.5926 33.2488C18.5926 33.5718 18.3298 33.8346 18.0068 33.8346H16.1715V27.9771H18.0068C18.3298 27.9771 18.5926 28.2399 18.5926 28.5629V33.2488ZM33.7053 28.9135L33.7052 28.9136L30.8931 32.6631C30.3429 33.3967 29.4671 33.8346 28.5501 33.8346H23.3201C22.1948 33.8346 21.0749 33.6802 19.9916 33.3757L19.7625 33.3113C19.7632 33.2905 19.7641 33.2698 19.7641 33.2488V28.8277L20.1723 28.4685C21.2753 27.4979 22.9388 27.4978 24.0419 28.4685C24.5403 28.907 25.1804 29.1486 25.8443 29.1486H28.5503C28.8732 29.1486 29.136 29.4114 29.136 29.7343C29.136 30.0573 28.8733 30.3201 28.5503 30.3201H24.3872C24.0638 30.3201 23.8015 30.5823 23.8015 30.9058C23.8015 31.2293 24.0637 31.4916 24.3872 31.4916H28.9427C29.4287 31.4916 29.8973 31.2877 30.2286 30.9321L32.8073 28.1638C32.9194 28.0434 33.0717 27.9771 33.2361 27.9771C33.5591 27.9771 33.8218 28.2399 33.8218 28.5629C33.8218 28.6906 33.7815 28.8119 33.7053 28.9135Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_7093_12753">
              <rect width="45" height="48" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
  );
};
