import { ShoppingProduct } from 'charlie-workflows/types';

import { GiftProductCard } from '../gift-product-card';

export const ShoppingProductCard: React.FC<ShoppingProduct['payload']> = ({
  overview,
  imageURL,

  ...props
}) => {
  const { productName, brandName } = props;

  return (
    <GiftProductCard
      brandName={brandName || ''}
      fullProductName={productName}
      details={{ fullDescription: overview, shortDescription: overview }}
      imageURL={imageURL?.[0]}
      {...props}
    />
  );
};
