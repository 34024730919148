import { Product } from 'charlie-workflows/types';
import React, { createElement } from 'react';

import { BookProductCard } from './variants/book-product-card';
import { GiftProductCard } from './variants/gift-product-card';
import { MusicProductCard } from './variants/music-product-card';
import { ShoppingProductCard } from './variants/shopping-product-card';

import styles from './preview-product-card.module.scss';

export interface ProductCardExtensionProps {}

export interface ProductCardProps {
  isSkeleton?: boolean;
  product?: Product;
  isInWishlist?: boolean;
  onClick?: () => void;
  extension?: React.FC<ProductCardProps>;
  className?: string;
}

function getProductCardComponentByType(product?: Product) {
  let prod;
  if (product) {
    // TODO avoid when will be implemented product versioning
    prod = (product as any)?.productId ? product : (product?.payload as any);
  } else {
    prod = null;
  }

  switch (product?.type) {
    case 'gift':
      return <GiftProductCard {...prod} />;
    case 'music':
      return <MusicProductCard {...prod} />;
    case 'book':
      return <BookProductCard {...prod} />;
    case 'shopping':
      return <ShoppingProductCard {...prod} />;
    default:
      return <GiftProductCard {...prod} />;
  }
}

export const PreviewProductCard: React.FC<ProductCardProps> = (props) => {
  const { className, extension, product } = props;

  return (
    <div
      className={`${className} ${styles.productCardContainer} ${
        !product ? styles.emptyCardContainer : ''
      }`}
    >
      {getProductCardComponentByType(product)}
      {product && extension ? createElement(extension, props) : null}
      {/* <div className={styles.extensionContainer}> */}
      {/* </div> */}
    </div>
  );
};
