import { isDate } from 'date-fns';
import { NextApiRequest } from 'next';

export const getIp = (request: NextApiRequest): string | undefined => {
  let ip: string | undefined;
  if (request.headers['x-forwarded-for']) {
    const xForwardedFor = request.headers['x-forwarded-for'];
    ip = Array.isArray(xForwardedFor)
      ? xForwardedFor[0].split(',')[0]
      : xForwardedFor.split(',')[0];
  } else if (request.headers['x-real-ip']) {
    ip = request.connection.remoteAddress;
  } else {
    ip = request.connection.remoteAddress;
    // ip = "206.71.50.230"; // NY IP address
  }

  return ip;
};

export const getProductImageUrl = (urls: string[] | string | undefined) => {
  return Array.isArray(urls) ? urls?.[0] || undefined : urls;
};

export const logger = (...args: any[]) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(...args);
  }
};

export const cutOffset = (
  date: string | Date | null,
  offset: number = new Date().getTimezoneOffset() * 60000,
) => {
  if (!date) {
    return null;
  }
  return new Date(Number(isDate(date) ? date : new Date(date)) + offset);
};
